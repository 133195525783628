import '@/css/app.pcss';

// For the on Doc ready: Define a convenience method and use it
let ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
}


ready(() => {
  /* Do things after DOM has fully loaded */

  //--------------------------------------------------------------- Navigation
  
  
  const toggleMenu = document.querySelector('.hamburger');
  const pageBody = document.querySelector('.page-body');
  const menu = document.querySelector('.main-menu');
  
  // check that the toggleMenu element exists before we attempt to attach the event listener
  if(typeof(toggleMenu) != 'undefined' && toggleMenu != null){
    
    /* When you click on the button, we call a function that will get the current value of the "aria-expanded" attribute, and invert it. It will do the same for the "hidden" attribute on the menu. When the menu is visible, we'll focus the first link so it gets properly announced. */
    
    toggleMenu.addEventListener('click', function () {
      
      // The JSON.parse function helps us convert the attribute from a string to a real boolean
      const open = JSON.parse(toggleMenu.getAttribute('aria-expanded'));
      
      // whatever the value of open is set it to the opposite
      toggleMenu.setAttribute('aria-expanded', !open);
      
      // whatever the value of the html element 'hidden' on menu is set it to the opposite
      menu.hidden = !menu.hidden;
      
      pageBody.classList.toggle("navigation-active-mobile");
      toggleMenu.classList.toggle("is-active");
      
      // if the menu isn't hidden focus on the first menu link
      if (!menu.hidden) {
        menu.querySelector('a').focus();
      }
      
    });
    
  }
  
  
  
  // Drop down menus on navigation
  
  var menuItems = document.querySelectorAll('li.has-submenu');
  
  Array.prototype.forEach.call(menuItems, function(el, i){
    var activatingA = el.querySelector('a');
    var btn = '<button class="submenu-button-icon"><span><span class="visuallyhidden">show submenu for “' + activatingA.text + '”</span></span></button>';
    activatingA.insertAdjacentHTML('afterend', btn);
  
    el.querySelector('button').addEventListener("click",  function(event){
      if (this.parentNode.className == "has-submenu") {
        this.parentNode.className = "has-submenu open";
        this.parentNode.querySelector('a').setAttribute('aria-expanded', "true");
        this.parentNode.querySelector('button').setAttribute('aria-expanded', "true");
      } else {
        this.parentNode.className = "has-submenu";
        this.parentNode.querySelector('a').setAttribute('aria-expanded', "false");
        this.parentNode.querySelector('button').setAttribute('aria-expanded', "false");
      }
      event.preventDefault();
    });
  });
  
  //--------------------------------------------------------------- Sticky Header
    
  // Debounce limits how often the scroll function fires
  function debounce(method, delay) {
    clearTimeout(method._tId);
    method._tId= setTimeout(function(){
      method();
    }, delay);
  }
  
  window.onscroll = function() {
    debounce(scrollFunction, 100);
  };
  
  const stickyHeader = document.getElementById('site-header');
  
  let oldValue = 0;
  let newValue = 0;
  
  function scrollFunction() {
    
  newValue = window.pageYOffset;
  if (oldValue < newValue) {
  
    // console.log("Scrolling Down");
  
    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
      
      setTimeout(()=> {
        pageBody.classList.remove("site-header-fixed");
        pageBody.classList.remove("site-header-fixed-visible");
      }
      ,500);
      
    } else {
      
      pageBody.classList.remove("site-header-fixed");
      pageBody.classList.remove("site-header-fixed-visible");
      
    }
      
  
  } else if (oldValue > newValue) {
  
    // console.log("Scrolling Up");
    
    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        
      pageBody.classList.add("site-header-fixed");
      
      setTimeout(()=> {
        pageBody.classList.add("site-header-fixed-visible");
      }
      ,500);
      
    } else {
      
      pageBody.classList.remove("site-header-fixed");
      
    }
  
  
  
  }
    
    oldValue = newValue;
    
    
  }


}); // END DOC READY